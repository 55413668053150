<template>
  <div class="system">
    <side-nav :active-tab="activeTab" :menu-items="userInfo.menuItems"/>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import SideNav from '@/views/system/components/SideNav';
import { mapState } from 'vuex';

export default {
  name: 'Index',
  components: { SideNav },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  data() {
    return {
      activeTab: '',
      menuItems: []
    };
  }
};
</script>

<style lang="less" scoped>
.system {
  width: 100%;
  //display: flex;
  //position: absolute;
  //top: 48px;
  //bottom: 0;

  .content {
    //flex: 1;
    padding: 16px;
    //max-width: calc(100% - 160px);
    //height: calc(100% - 100px);
    //position: relative;
    //margin-top: 48px; /* 与菜单栏高度相同 */
    margin-left: 160px; /* 与目录宽度相同 */
    height: calc(100vh - 48px); /* 视口高度减去菜单栏高度 */
    overflow-y: auto; /* 超出内容滚动 */
  }
}
</style>
